<template>
  <v-data-table
    :items="inventory"
    :headers="headers"
    :options.sync="options"
    :loading="loading"
    :expanded.sync="expanded"
    loading-text="Loading... Please wait"
    mobile-breakpoint="0"
    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"

  >
    <template slot="body.append">
      <tr>
        <th>Totals:</th>
        <th v-bind:class="getClassForBalance()">{{ balance }} </th>
        <th>{{ getItemsSold }}</th>
        <th>{{ getItemsCost }}</th>
        <th>{{ getItemsValue }}</th>
      </tr>
    </template>

    <template #top>
        <v-row class="text-right">
        <v-col cols="12" >
          <v-dialog v-model="import_dialog" width="500">
            <template #activator="{ on, attrs }">
              <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" >
                Import New
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline grey lighten-2">
                Put the domain names below
              </v-card-title>

              <v-container fluid>
                <v-textarea
                    v-model="domain_names_bunch"
                    counter
                    label="One per line or space in between"
                />
              </v-container>

              <v-divider />

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="importDomains">
                  Import
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
              class="ma-2"
              :loading="cnmSync"
              :disabled="cnmSync"
              color="info"
              @click="syncCNMAction"
          >
            Sync CNM
            <template #loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
        </v-row>
    </template>

    <template #[`item.name`]="{ item }">
      <domain-menu :name="item.name"/>
    </template>
    <template #[`item.listedDate`]="{ item }">
      {{ new Date(item.listedDate).toISOString().split('T')[0] }}
    </template>
    <template #[`item.sold`]="{ item }">
      <v-checkbox v-model="item.sold" @click.stop="updateInventoryDomain(item)" />
    </template>
    <template #[`item.letGo`]="{ item }">
      <v-checkbox v-model="item.letGo" @click.stop="updateInventoryDomain(item)" />
    </template>
    <template #[`item.listedAtCNM`]="{ item }">
      <v-icon :color="item.listedAtCNM ? 'green' : 'red'">
        {{ item.listedAtCNM ? 'mdi-check-circle' : 'mdi-cancel' }}
      </v-icon>
    </template>


    <template #[`item.listedPrice`]="{ item }">
      {{ item.price ? item.price : "not listed" }}
    </template>
    <template #[`expanded-item`]="{ headers, item }">
      <td :colspan="headers.length">
        <inventory-domain-card @collapse="collapseCurrentRow(item)" v-if="item" :inventory-item="item" />
      </td>
    </template>
    <template #[`item.info`]="{ item }">
      <domain-card :domain-name="item.name"/>
    </template>
    <template #[`item.actions`]="{ item }">
      <list-domain-menu-small
          :domain-name="item.name"
      />
      <new-lead-from-inventory
          :domain-name="item.name"
          :sibling="item.name"
      />
      <evaluation-menu
        :domain-name="item.name"
        :suggested-price="item.evaluation"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import InventoryDomainCard from "@/components/domains/InventoryDomainCard";
import EvaluationMenu from "@/components/EvaluationMenu";
import ListDomainMenuSmall from "@/components/ListDomainMenuSmall";
import DomainMenu from "@/components/DomainMenu";
import DomainCard from "@/components/domains/DomainCard.vue";
import NewLeadFromInventory from "@/components/NewLeadFromInventory.vue";

export default {
  components: {
    EvaluationMenu,
    NewLeadFromInventory,
    DomainCard,
    DomainMenu,
    ListDomainMenuSmall,
    InventoryDomainCard,
  },
  // components: { DomainCard, SendWithDan, DomainMenu },

  data() {
    return {
      expanded: [], // Tracks expanded rows
      calendar: false,
      import_dialog: false,
      options: {
        itemsPerPage: 50,
      },
      domain_names_bunch: "",
    };
  },
  computed: {
    ...mapGetters({
      inventory: "inventory/getInventory"}),

    ...mapState({
      cnmSync: (state) => state.inventory.cnmSync,

    }),
    balance(){
      return this.getItemsSold-this.getItemsCost;
    },
    getItemsCost(){
      return this.inventory.reduce((acc, curr)=> acc+curr.purchasePrice, 0);
    },
    getItemsValue(){
      return this.inventory.reduce((acc, curr)=> acc+curr.price, 0);
    },
    getItemsSold(){
      let sold = this.inventory.filter(item => item.sold)
      return sold.reduce((acc, curr)=> acc+curr.price, 0);
    },

    loading: {
      get() {
        return this.$store.state.inventory.loading;
      },
      set(val) {
        this.$store.commit("inventory/SET_LOADING", val);
      },

    },
    headers() {
      return [
        { text: "Domain", value: "name", width: "300px" },
        { text: "Listed", value: "listedDate" },
        { text: "Registrar", value: "registrar" },
        { text: "Sold", value: "sold" },
        { text: "Let Go", value: "letGo" },
        { text: "Purchased", value: "purchasePrice" },
        { text: "Listed", value: "listedPrice" },
        { text: "CNM", value: "listedAtCNM" },
        { text: "Status", value: "status" },
        { text: "Info", value: "info" },
        { text: "Actions", value: "actions" },
      ];
    },
  },
  methods: {
    ...mapActions("inventory", [
      "importInventoryAction",
      "syncCNMAction",
      "updateInventoryDomain",
    ]),
    ...mapActions("conversations", ["rescanDomainAction"]),

    importDomains() {
      this.domain_names_bunch = this.domain_names_bunch.replace(/ /g, "");
      const re = /[\n]+/;
      let nameList = this.domain_names_bunch.split(re);
      this.importInventoryAction(nameList);
      this.import_dialog = false;
    },
    collapseCurrentRow(item) {
      const index = this.expanded.indexOf(item);
      if (index !== -1) {
        this.expanded.splice(index, 1); // Collapse the current row
      }
    },

    // rescan(item) {
    //   this.rescanDomainAction(item.name);
    // },
    getClassForBalance(){
      return this.balance>0?"green--text":"red--text";
    }
  },

  // mounted(){
  //   const searchFilter = {
  //     dropDate: new Date()
  //   }
  //   this.$store.dispatch(`${this.$route.name}/search`, searchFilter)
  // }
};
</script>

<style></style>
